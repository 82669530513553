import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';

import Amplify from 'aws-amplify';
// import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import config from './config';


// import "./App.css";


import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css';
import 'mdbreact/dist/mdbreact'
import './Apps.scss';

import App from './App';


Amplify.configure({
  
  API: {
    endpoints: [     
      {
        name: "fl",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});

//  Amplify.addPluggable(new AWSIoTProvider({
//     aws_pubsub_region: config.REACT_APP_REGION,
//     aws_pubsub_endpoint: `wss://${config.REACT_APP_MQTT_ID}.iot.${config.REACT_APP_REGION}.amazonaws.com/mqtt`
//   }));


ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
