export const defaultFields = [
  {
    "id": "choose_course",
    "name": "Course",
    "type": "select",
    "label": "Choose Course",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "options": [
      {
        "heading": "Courses",
        "items": [
          {
            "label": "Big Data/Data Science/ML Foundation Course",
            "value": "Big Data/Data Science/ML Foundation Course"
          },
          {
            "label": "Project Management Professional PMP®",
            "value": "Project Management Professional PMP®"
          },
          /* {
            "label": "Cyber Security Foundation",
            "value": "Cyber Security Foundation"
          },
          {
            "label": "Software/Web Development",
            "value": "Software/Web Development"
          },
          {
            "label": "Robotic Process Automation (RPA) Foundation Course",
            "value": "Robotic Process Automation (RPA) Foundation Course"
          }, */
          {
            "label": "PMI ACP Training Course",
            "value": "PMI ACP Training Course"
          }   
        ]
      }
    ],
    "visible": true,
    "required": true,
    "disabled": false,
    "visibleWhen": [],
    "requiredWhen": [],
    "disabledWhen": [],
    "shouldMatchRegex": false,
    "hasMinLength": false,
    "hasMaxLength": false,
    "hasNumericalRange": false,
    "shouldCompareTo": false,
    "autofocus": true
  },
  {
    "id": "choose_dates_big_data_data_science_ml_foundation",
    "name": "Big Data/Data Science/ML Foundation Course Date",
    "type": "select",
    "label": "Big Data/Data Science/ML Course Dates",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "options": [
      {
        "heading": "Course dates",
        "items": [
          {
          "label": "September 29-30 (Thu-Fri) 9:00AM-17:45PM virtual",
          "value": "September 29-30 (Thu-Fri) 9:00AM-17:45PM virtual"
          },
          {
          "label": "October 27-28 (Thu-Fri) 9:00AM-17:45PM virtual",
          "value": "October 27-28 (Thu-Fri) 9:00AM-17:45PM virtual"
          },
          {
          "label": "November 24-25 (Thu-Fri) 9:00AM-17:45PM physical",
          "value": "November 24-25 (Thu-Fri) 9:00AM-17:45PM physical"
          },
          {
          "label": "December 29-30 (Thu-Fri) 9:00AM-17:45PM physical",
          "value": "December 29-30 (Thu-Fri) 9:00AM-17:45PM physical"
          },
        ]
      }
    ],
    "visible": false,
    "required": true,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "Course is Big Data/Data Science/ML Foundation Course",
        "field": "choose_course",
        "is": [
          {
            "value": "Big Data/Data Science/ML Foundation Course"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": []
  },
  {
    "id": "choose_dates_pmp",
    "name": "Project Management Professional PMP® Date",
    "type": "select",
    "label": "Project Management Professional PMP® Dates",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "options": [
      {
        "heading": "Course dates",
        "items": [
          {
          "label": "September 17-18, 24-25 (Sat-Sun, Sat-Sun) 9:00AM-17:45PM physical",
          "value": "September 17-18, 24-25 (Sat-Sun, Sat-Sun) 9:00AM-17:45PM physical"
          },
          
          {
          "label": "October 1-2, 8-9 (Sat-Sun, Sat-Sun) 9:00AM-17:45PM virtual",
          "value": "October 1-2, 8-9 (Sat-Sun, Sat-Sun) 9:00AM-17:45PM virtual"
          },

          {
          "label": "November 19-20, 26-27 (Sat-Sun, Sat-Sun) 9:00AM-17:45PM virtual",
          "value": "November 19-20, 26-27 (Sat-Sun, Sat-Sun) 9:00AM-17:45PM virtual"
          },

          {
          "label": "December 10-11, 17-18 (Sat-Sun, Sat-Sun) 9:00AM-17:45PM virtual",
          "value": "December 10-11, 17-18 (Sat-Sun, Sat-Sun) 9:00AM-17:45PM virtual"
          },
            
        ]
      }
    ],
    "visible": false,
    "required": true,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "Course is PMP",
        "field": "choose_course",
        "is": [
          {
            "value": "Project Management Professional PMP®"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": []
  },
  {
    "id": "choose_dates_cyber",
    "name": "Cyber Security Foundation Date",
    "type": "select",
    "label": "Cyber Security Foundation Dates",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "options": [
      {
        "heading": "Cyber Security Foundation Dates",
        "items": []
      }
    ],
    "visible": false,
    "required": true,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "Course is Cyber Security Foundation",
        "field": "choose_course",
        "is": [
          {
            "value": "Cyber Security Foundation"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": []
  },
  {
    "id": "choose_dates_robotic_process_automation",
    "name": "Robotic Process Automation (RPA) Foundation Date",
    "type": "select",
    "label": "Robotic Process Automation Foundation Dates",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "options": [
      {
        "heading": "Robotic Process Automation Foundation Dates",
        "items": []
      }
    ],
    "visible": false,
    "required": true,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "Course is Robotic Process Automation Foundation",
        "field": "choose_course",
        "is": [
          {
            "value": "Robotic Process Automation (RPA) Foundation Course"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": []
  },
  {
    "id": "choose_dates_web",
    "name": "Software/Web Development Date",
    "type": "select",
    "label": "Software/Web Development Dates",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "options": [
      {
        "heading": "Software/Web Development Dates",
        "items": []
      }
    ],
    "visible": false,
    "required": true,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "Choose date for Software/Web Development",
        "field": "choose_course",
        "is": [
          {
            "value": "Software/Web Development"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": []
  },
  {
    "id": "choose_dates_pmi_acp_course",
    "name": "PMI ACP Training Course Date",
    "type": "select",
    "label": "PMI ACP Training Course Course Dates",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "options": [
      {
        "heading": "Course dates",
        "items": [
          {
          "label": "September 15-16 (Thu-Fri) 9:00AM-17:45PM virtual",
          "value": "September 15-16 (Thu-Fri) 9:00AM-17:45PM virtual"
          },
          {
          "label": "October 6-7 (Thu-Fri) 9:00AM-17:45PM virtual",
          "value": "October 6-7 (Thu-Fri) 9:00AM-17:45PM virtual"
          },
          {
          "label": "November 10-11 (Thu-Fri) 9:00AM-17:45PM virtual",
          "value": "November 10-11 (Thu-Fri) 9:00AM-17:45PM virtual"
          },
          {
          "label": "December 1-2 (Thu-Fri) 9:00AM-17:45PM virtual",
          "value": "December 1-2 (Thu-Fri) 9:00AM-17:45PM virtual"
          },
        ]
      }
    ],
    "visible": false,
    "required": true,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "Course is PMI ACP Training Course",
        "field": "choose_course",
        "is": [
          {
            "value": "PMI ACP Training Course"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": []
  },  
  {
    "id": "coding_experience",
    "name": " Coding",
    "type": "text",
    "label": "Years of coding experience",
    "description": "For the course selected, you dont need to have previous coding experience.",
    "placeholder": "",
    "defaultValue": "",
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^[\\w ]+$",
        "message": "Remove any special characters"
      }
    },    
    "hasMinLength": false,
    "hasMaxLength": false,
    "hasNumericalRange": false,
    "shouldCompareTo": false,    
    "visible": false,
    "required": false,
    "disabled": false,

   "visibleWhen": [
      {
        "id": "Python coding",
        "field": "choose_course",
        "is": [
          {
            "value": "Big Data/Data Science/ML Foundation Course"
          },
          {
            "value": "Cyber Security Foundation"
          },
          {
            "value": "Software/Web Development"
          },
          {
            "value": "Robotic Process Automation (RPA) Foundation Course"
          }      
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": []
  },
  {
    "id": "full_name",
    "name": "Name",
    "type": "text",
    "label": "Trainee Full Name",
    "description": "",
    "placeholder": "Enter your full name",
    "defaultValue": "",
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^[\\w ]+$",
        "message": "Remove any special characters"
      }
    },    
    "hasMinLength": false,
    "hasMaxLength": false,
    "hasNumericalRange": false,
    "shouldCompareTo": false,
    "visible": true,
    "required": true,
    "disabled": false,
    "visibleWhen": [],
    "requiredWhen": [],
    "disabledWhen": [],
    "options": [],
    "shouldFitContainer": true
  },
  {
    "id": "country",
    "name": "Country",
    "type": "text",
    "label": "Country from where you will attend training",
    "description": "",
    "placeholder": "Enter Country for training",
    "defaultValue": "",
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^[\\w ]+$",
        "message": "Remove any special characters"
      }
    },    
    "hasMinLength": false,
    "hasMaxLength": false,
    "hasNumericalRange": false,
    "shouldCompareTo": false,
    "visible": true,
    "required": true,
    "disabled": false,
    "visibleWhen": [],
    "requiredWhen": [],
    "disabledWhen": [],
    "options": [],
    "shouldFitContainer": true
  },
  {
    "id": "hand_phone",
    "name": "Hand Phone Number",
    "type": "text",
    "label": "Trainee cell phone number",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^[\\w ]+$",
        "message": "Remove any special characters"
      }
    },
    "hasMinLength": false,
    "hasMaxLength": false,
    "hasNumericalRange": false,
    "shouldCompareTo": false,
    "visible": true,
    "required": true,
    "disabled": false,
    "visibleWhen": [],
    "requiredWhen": [],
    "disabledWhen": [],
    "shouldFitContainer": true
  },
  {
    "id": "email",
    "name": "Email",
    "type": "text",
    "label": "Trainee email address",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^\\S+@\\S+$",
        "message": ""
      }
    },
    "visible": true,
    "required": true,
    "disabled": false,
    "visibleWhen": [],
    "requiredWhen": [],
    "disabledWhen": [],
    "shouldFitContainer": true
  },
  {
    "id": "self_or_company",
    "name": "Self sponsored or Company Sponsored",
    "type": "radiogroup",
    "label": "Company sponsored or Self Sponsored",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "options": [
      {
        "heading": "Company or Self Sponsored",
        "items": [
          {
            "label": "Company Sponsored",
            "value": "Company Sponsored"
          },
          {
            "label": "Self Sponsored",
            "value": "Self Sponsored"
          }
        ]
      }
    ],
    "visible": true,
    "required": true,
    "disabled": false,
    "visibleWhen": [],
    "requiredWhen": [],
    "disabledWhen": []
  },
  {
    "id": "company_sponsored_company_name",
    "name": "Company Name",
    "type": "text",
    "label": "Company Name",
    "description": "Provide full name of your company",
    "placeholder": "",
    "defaultValue": "",
    "visible": false,
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^[\\w ]+$",
        "message": "Remove any special characters"
      }
    }, 
    "required": false,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "company sponsored is selected",
        "field": "self_or_company",
        "is": [
          {
            "value": "Company Sponsored"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": [],
    "shouldFitContainer": true
  },
  {
    "id": "company_sponsored_company_contact",
    "name": "Company Contact Person",
    "type": "text",
    "label": "Name of Company Contact Person",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^[\\w ]+$",
        "message": "Remove any special characters"
      }
    }, 
    "visible": true,
    "required": false,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "Contact person of company",
        "field": "self_or_company",
        "is": [
          {
            "value": "Company Sponsored"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": [],
    "shouldFitContainer": true
  },
  {
    "id": "company_sponsored_company_contact_email",
    "name": "Company Contact Person Email",
    "type": "text",
    "label": "Email of Company Contact Person",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "visible": true,
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^\\S+@\\S+$",
        "message": ""
      }
    },
    "required": false,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "Email of company contact",
        "field": "self_or_company",
        "is": [
          {
            "value": "Company Sponsored"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": [],
    "shouldFitContainer": true
  },
  {
    "id": "company_sponsored_company_contact_phone",
    "name": "Company Contact Person Phone",
    "type": "text",
    "label": "Phone no of Company Contact Person",
    "description": "",
    "placeholder": "",
    "defaultValue": "",
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^[\\w ]+$",
        "message": "Remove any special characters"
      }
    }, 
    "visible": true,
    "required": false,
    "disabled": false,
    "visibleWhen": [
      {
        "id": "Phone of company sponsored",
        "field": "self_or_company",
        "is": [
          {
            "value": "Company Sponsored"
          }
        ]
      }
    ],
    "requiredWhen": [],
    "disabledWhen": [],
    "shouldFitContainer": true
  },
  {
    "id": "comments",
    "name": "Comments",
    "type": "textarea",
    "label": "Comments",
    "shouldFitContainer": true,
    "shouldMatchRegex": true,
    "validWhen": {
      "matchesRegEx": {
        "pattern": "^[\\w ]+$",
        "message": "Remove any special characters"
      }
    }, 
    "description": "",
    "placeholder": "Specify any comments or questions here",
    "defaultValue": ""
  }
]


