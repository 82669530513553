import axios from 'axios'
import React, { Component } from 'react';
import registration_confirmation_706x470_v2 from '../images/malaysia/registration_confirmation_706x470_v2.svg';


import { MDBFileInput,
         MDBBadge,
         MDBProgress,
         MDBContainer,
         MDBRow,
         MDBCol,
         MDBCard,
         MDBChip,
         MDBView,
         MDBCardTitle,
         MDBCardHeader,
         MDBCardBody,
         MDBCardGroup,
         MDBCardFooter,
         MDBDataTable,
         MDBIcon,
         MDBIframe,
         MDBInput,         
         MDBListGroup,
         MDBListGroupItem,
         MDBMask,
         MDBPagination,
         MDBPageItem,
         MDBPageNav,
         MDBSpinner,
         MDBSelect,         
         MDBAvatar,
         MDBBtn,
         MDBTooltip,
         MDBTable,
         MDBTableEditable,         
         MDBModal,
         MDBModalHeader,
         MDBModalBody,
         MDBModalFooter } from 'mdbreact';

function handleCourseEnrol(item) {
        this.setState({
            submitted:true,
            item:item
        })
        console.log(item)

        const options = {
            url: `${process.env.REACT_APP_USERS_SERVICE_URL}/auth/contactus`,
            method: 'post',
            headers: {
                'Content-Type': 'application/json'                
            },
            data: {...item}
        }
        return axios(options)
        .then((res)=>{          

            
                
        })
        .catch((error)=>{console.log(error)})
    }


export default function showConfirmDetails(item) {

    return(
        <section id="older" className="section extra-margins text-center">
                

                <h2 className="text-center my-5 h1">Seat Reserved for <span className="teal-text"><strong>{item.Name}</strong></span></h2>
                <p className="text-center mb-5 w-responsive mx-auto">Thank you for your interest. We have reserved a slot for you in the below course. This is <span className="red-text">not a confirmation </span>of the class yet. Now that we have your details, one of our consultants will get in touch with you within the next 2 days to answer any questions you may have. In the next 2 days, please check your inbox for an mail from our consultant with the course details. </p>


                <MDBCol lg="12" className="mb-4">
                <MDBView waves hover className="z-depth-1">
                    <img src={registration_confirmation_706x470_v2} className="img-fluid" alt="ITPACS Malaysia Course registration"/>
                    
                        <MDBMask overlay="white-slight"/>
                    
                </MDBView>
                <h6 className="mb-3 mt-3 teal-text"><MDBIcon icon="graduation-cap"/><strong> {item.Name}</strong></h6>
                <h4 className="font-weight-bold mb-3 info">{item.Course}</h4>
                <p>for <strong>{item.Name}</strong> on {item[`${item.Course} Date`]}</p>
                
             </MDBCol>

             {/*
             <MDBCol lg="12" className="mb-2">
                <h5 className="font-weight-bold mb-3 info">Get in touch with us</h5>
                <MDBCard>
                  <MDBRow className="mt-4 mb-3">
                    <MDBCol md="3"  col="3" className="text-left pl-4">
                      <a className="p-2 m-2 fa-lg fb-ic" href={`https://api.whatsapp.com/send?phone=60167804328&text=Hi%20I%20am%20${item.Name}%20interested%20in%20the%20${item.Course}%20scheduled%20for%20${item[`${item.Course} Date`]}`} target="_blank" rel="noopener noreferrer">
                        <MDBIcon fab
                         icon="whatsapp" size="2x" className="green-text"/>
                      </a>
                    </MDBCol>
                    <MDBCol md="9"  col="9" className="text-right pr-5">
                      <p className="font-small grey-text mb-1">WhatsApp</p>
                      
                    </MDBCol>
                  </MDBRow>
                </MDBCard>   

                <MDBCard>
                  <MDBRow className="mt-4 mb-3">
                    <MDBCol md="3"  col="3" className="text-left pl-4">
                      
                        <MDBIcon
                         icon="phone" size="2x" className="amber-text"/>
                      
                    </MDBCol>
                    <MDBCol md="9"  col="9" className="text-right pr-5">
                      <p className="font-small grey-text mb-1">Call Us</p>
                      <h5 className="ml-4 mb-2 font-weight-bold">+60 3 9212 5851 </h5>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>

                <MDBCard>
                  <MDBRow className="mt-4 mb-3">
                    <MDBCol md="3"  col="3" className="text-left pl-4">
                      
                        <MDBIcon far
                         icon="envelope" size="2x" className="blue-text"/>
                      
                    </MDBCol>
                    <MDBCol md="9"  col="9" className="text-right pr-5">
                      <p className="font-small grey-text mb-1">Email</p>
                      <a href="mailto:malaysiacourses@itpacs.org" className="text-primary">malaysiacourses@itpacs.org</a>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
                
                </MDBCol>
            */}

                </section>

        )
}

// export default {showConfirmDetails}

