// March 22
const dev =  {
 
  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "https://api.neighborslah.com/dev"
  }

};

const prod =  {

  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "https://api.neighborslah.com/prod"
  }

};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  REACT_APP_REGION: "ap-southeast-1",
  REACT_APP_MQTT_ID: "a2o7xsheys8v6f-ats",
  ...config
}