// import logo1 from './components/pages/img/LOGO_WHITE.svg'
import logo1 from './components/pages/img/large-text-transparent.png'
import { Link } from 'react-router-dom';

import React, { Component } from "react";
        
import { MDBNavbar, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBNav, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBContainer, MDBIcon } from "mdbreact";

import Schedule from './containers/Schedule';
import Pricing  from './containers/Pricing';

import showConfirmDetails from './containers/HandleForms.js'
import {defaultFields} from './containers/enrol_course_form_fields.js'
import {Form} from "react-forms-processor"
import {renderer, FormButton} from "react-forms-processor-atlaskit"

import Amplify, { API } from 'aws-amplify';

class TopNavigation extends Component {


  constructor(props) {
        super(props);
        this.state = {
            collapseID: "",
            isOpen: false,
            enrolform: false,
            // submitted:false
            
        }

    this.renderModal = this.renderModal.bind(this)
    this.openModal = this.openModal.bind(this)
    // this.handleCourseEnrol = this.handleCourseEnrol.bind(this)

    }

    

 // async handleCourseEnrol(item){
        
        
 //        // console.log(item)

 //        // const options = {
 //        //     url: `${process.env.REACT_APP_USERS_SERVICE_URL}/auth/contactus`,
 //        //     method: 'post',
 //        //     headers: {
 //        //         'Content-Type': 'application/json'                
 //        //     },
 //        //     data: {...item}
 //        // }
 //        // return axios(options)
 //        // .then((res)=>{          

            
                
 //        // })
 //        // .catch((error)=>{console.log(error)})


 //    try {

 //    const new_signup = await API.post("fl", "/fl2/ikompasssignup", {
 //        body: {
 //          signup_details: {...item}}

 //      })

 //    this.setState({
 //            submitted:true,
 //            item:item
 //        })
      

 //    } catch(e){
 //      console.log(e)
 //    }


 //    }



toggleCollapse = collapseID => () =>
  this.setState(prevState => ({
  collapseID: prevState.collapseID !== collapseID ? collapseID : ""
}));


openModal() {
        this.setState({ isOpen: true })
    }

toggle = (item) => {

      console.log(item)
      this.setState({

        [item]: !this.state[item]
        

      },  ()=>{
          this.props.setSubmitted(false)
          this.props.setItem('')
            }
        

        )
  }

  renderModal = (type, size) =>{

        const string = type === 'enrolform' ?
            'Enrol' : type === 'showpricing' ?
            'Pricing': type === 'showschedule' ?
            'Schedule': 'Details'

        const icon = type === 'enrolform' ?
            'user': 'user'

        const lBtnTxt = type === 'enrolform' ?
                  'close' : 'close'

        const rBtnTxt = type === 'enrolform' ?
                  'Enrol' : 'Submit'

        const rBtnIcon = type === 'enrolform' ?
                  'user' : 'arrow-right'
         
        return (
        <MDBModal isOpen={this.state[type]} toggle={()=>this.toggle(type)} size={size} cascading>
            <MDBModalHeader toggle={()=>this.toggle(type)} className="light-blue darken-3 white-text">
                <MDBIcon icon={icon} className="mr-2"/> {string}
            </MDBModalHeader>  
            <MDBModalBody>


            {!this.props.submitted &&
                <div>
                {
                    type === 'enrolform' ?
                    <div>
                    <Form renderer={renderer} defaultFields={defaultFields}>
                    <FormButton 
                        onClick={this.props.handleCourseEnrol}
                    />
                    </Form>
                    </div>
                    : <p></p>
                }
                </div>
            }
            {
                this.props.submitted &&

                <div>
                  {showConfirmDetails(this.props.item)}
                <MDBBtn outline color="primary" onClick={()=>this.toggle(type)}>{lBtnTxt}</MDBBtn>
                </div>

            }


            {
            type === 'showpricing' ?
            <MDBContainer>
              <Pricing 
                   toggle={this.toggle.bind(this)}
                   toggle2={this.toggle.bind(this)} />
            </MDBContainer>
            : type === 'showschedule' ?
            <MDBContainer>
              <Schedule 
                 toggle={this.toggle.bind(this)}
                 toggle2={this.toggle.bind(this)} />
            </MDBContainer>
            : <p></p>

            }


            </MDBModalBody>
            
        
        </MDBModal>
        )
    }

render() {
  return (
    <div>
    
    {this.renderModal('showpricing', 'fluid')}
    {this.renderModal('showschedule', 'fluid')}
    {this.renderModal('enrolform', 'lg')}
    
      <MDBNavbar dark expand="md" style={{ marginTop: "0px", backgroundColor:'#581C73' }} scrolling fixed="top">
        <MDBNavbarBrand href="/">
            <img src={logo1} height="20" className="d-inline-block align-center" alt="ikompass"/>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse("navbarCollapse3")} />
        <MDBCollapse id="navbarCollapse3" isOpen={this.state.collapseID} navbar>
          <MDBNavbarNav right>
             
             <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <MDBIcon icon="list" className="mr-1" />Trainings
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default" right>
                  {/*
                    <MDBDropdownItem href="/">My account</MDBDropdownItem>
                  <MDBDropdownItem href="#!">Log out</MDBDropdownItem>
                */}
                    <MDBNavLink to='/trainings/pmp-certification-training-singapore'><MDBDropdownItem>Project Management Professional PMP® Training</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to='/data/science/training/singapore'><MDBDropdownItem>Big Data/Data Science/ML Foundation</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to='/trainings/web-development-coding-singapore'><MDBDropdownItem>Software/Web Development Foundation</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to='/cyber-security-foundation-course-singapore'><MDBDropdownItem>Cyber Security Foundation</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to='/trainings/rpa-robotic-process-automation-course-singapore'><MDBDropdownItem>Robotic Process Automation Foundation</MDBDropdownItem></MDBNavLink>
                    <MDBNavLink to='/trainings/pmi_acp-singapore'><MDBDropdownItem>PMI Agile Certified Practitioner</MDBDropdownItem></MDBNavLink>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              
                  
                    <MDBNavLink onClick={()=>this.toggle('showschedule')} className="waves-effect waves-light" to="#">
                      <MDBIcon icon="calendar" className="mr-1" />
                        Schedule
                    </MDBNavLink>
                  
               
            </MDBNavItem>

            <MDBNavItem>
              
                  
                    <MDBNavLink onClick={()=>this.toggle('showpricing')} className="waves-effect waves-light" to="#">
                      <MDBIcon icon="dollar-sign" className="mr-1" />
                        Pricing
                    </MDBNavLink>
                  
              
            </MDBNavItem>
                               
            
            <MDBNavItem style={{backgroundColor:'orange'}}>
                    <MDBNavLink onClick={()=>this.toggle('enrolform')} className="waves-effect waves-light" to="#">
                      <MDBIcon icon="user" className="mr-1" />
                      Enrol
                      </MDBNavLink>
            </MDBNavItem>

            
            {/*
            <MDBNavItem style={{backgroundColor:'orange'}}>
              <MDBNavLink className="waves-effect waves-light" to="#">
                <MDBIcon icon="user" className="mr-1" />Enrol</MDBNavLink>
            </MDBNavItem>
          */}
            

            
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
      
    </div>
    );
  }
}

export default TopNavigation;


// import React from 'react';
// import { Navbar, NavbarBrand, NavbarNav, NavbarToggler, Collapse, NavItem, NavLink} from 'mdbreact';

// import { MDBNavbar,
//          MDBNavbarBrand,
//          MDBNavbarNav,
//          MDBNavItem,
//          MDBNavLink,
//          MDBIcon,
//          MDBDropdown,
//          MDBDropdownToggle,
//          MDBDropdownMenu,
//          MDBDropdownItem,
//          MDBBadge
// } from 'mdbreact';

// const Link = require("react-router-dom").NavLink;

// class TopNavigation extends React.Component {
//   constructor(props) {
//       super(props);
//       this.state = {
//           collapse: false
//       };
//   this.onClick = this.onClick.bind(this);
//   this.toggle = this.toggle.bind(this);
//   this.handleToggleClickA = this.handleToggleClickA.bind(this);
// }

// onClick(){
//   this.setState({
//       collapse: !this.state.collapse,
//     });
// }

// toggle() {
//         this.setState({
//             dropdownOpen: !this.state.dropdownOpen
//         });
//     }

// handleToggleClickA() {

//         this.props.onSideNavToggleClick();
//     }

// render() {

//           const navStyle = {
//             paddingLeft:
//               this.props.toggle ? '16px' : "240px",
//               transition: 'padding-left .3s'
//           };
  
//     return (
        
                 
//               <MDBNavbar className="flexible-MDBNavbar" style={{backgroundColor:'#581C73'}} dark expand="md" scrolling fixed="top">
  
//                 <NavbarBrand href="/">
//                     <img src={logo1} height="20" className="d-inline-block align-top" alt=""/>
//                 </NavbarBrand>

               


//                 <MDBNavbarNav expand="sm" right style={{flexDirection: 'row'}}>
             
//                         <MDBNavItem>
//                             <MDBNavLink to="/">
//                                 <MDBIcon icon="home" className="amber-text" />
//                                 <span className="d-none d-md-inline ml-1">Home</span>
//                             </MDBNavLink>
//                         </MDBNavItem>

                        
//                         <MDBNavItem>
//                             <MDBNavLink to="/domains">
//                                 <MDBIcon icon="graduation-cap" className="white-text" />
//                                 <span className="d-none d-md-inline ml-1">Certifications</span>
//                             </MDBNavLink>
//                         </MDBNavItem>

//                         <MDBNavItem>
//                             <MDBNavLink to="/about"><MDBIcon icon="address-card" className="cyan-text"/>
//                                 <span className="d-none d-md-inline ml-1">About</span>
//                             </MDBNavLink>
//                         </MDBNavItem>
//                         <MDBNavItem>
//                             <MDBNavLink to="/trainingproviders"><MDBIcon icon="handshake-o" className="orange-text" />
//                                 <span className="d-none d-md-inline ml-1">Training Providers</span>
//                             </MDBNavLink>
//                         </MDBNavItem>
                      

        
//                         <MDBNavItem>
//                             <MDBNavLink to="/search"><MDBIcon icon="search" className="cyan-text" />
//                                 <span className="d-none d-md-inline ml-1"></span>
//                             </MDBNavLink>
//                         </MDBNavItem>

//                         {!this.props.isAuthenticated &&

//                           <MDBNavItem>
//                             <MDBDropdown>
//                               <MDBDropdownToggle nav caret>
//                                 <MDBIcon icon="user" />
//                               </MDBDropdownToggle>
//                               <MDBDropdownMenu className="dropdown-default">
//                                 <MDBNavLink to='/login'><MDBDropdownItem>Login</MDBDropdownItem></MDBNavLink>
//                                 <MDBNavLink to='/signup'><MDBDropdownItem>Register</MDBDropdownItem></MDBNavLink>                                
//                               </MDBDropdownMenu>
//                             </MDBDropdown>
//                           </MDBNavItem>



//                         }

                        

//                     </MDBNavbarNav>  
                
//             </MDBNavbar>

      
//     );
//   }
// }

// export default TopNavigation;



