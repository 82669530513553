import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
// import { Nav, Navbar, NavItem } from "react-bootstrap";
// import "./App.css";


// import './css/materialdesignicons.min.css';


import Routes from "./Routes"
// import { Auth } from "aws-amplify";
import { API } from "aws-amplify";

// import SideNavigation from './SideNavigation.js';
import TopNavigation from './TopNavigation.js'

import Message from './Message.js'

import { LinkContainer } from "react-router-bootstrap";

import { MDBContainer, MDBIcon, MDBBtn, MDBRow } from "mdbreact";

import Topbar from './Topbar';
import NavBarMultiPage from './NavBarMultiPage';

import Footer from './Footer';
import Page from "react-page-loading";

import ScrollUpBtn from "./components/ScrollUpBtn";

function App(props) {


    const [messageName, setMessageName] = useState(null)
    const [messageType, setMessageType] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [item, setItem] = useState('')
    



    useEffect(()=>{

      // onLoad()
      console.log(props.location.pathname)
      // assessLocation(props.location.pathname)
      

    }, [])

    

    async function handleCourseEnrol(item){

      console.log('Hello from iKompass')
      console.log(item)
    setSubmitted(true)
    setItem(item)

    try {
          const new_signup = await API.post("fl", "/fl2/ikompasssignup", {
              body: {
                signup_details: {...item}}

            })

          


    } catch(e){
      console.log(e)
    }


    }

    
    function removeMessage(){
      setMessageName(null)
      setMessageType(null)
    }

    function createMessage({name, type}){
      setMessageType(type)
      setMessageName(name)

      setTimeout(()=>{
        removeMessage()
      }, 3000)
    }



    async function loadUser(username){
      return API.get("fl", `/fl/getuser/${username}`)
    }

   


    
  

  return (

    <React.Suspense fallback={<div>Loading....</div>}>
    
    <div className="app">
    <Page loader={"bar"} color={"#581C73"} size={9}>
     

      <TopNavigation               
        createMessage={createMessage}
        removeMessage={removeMessage}
        handleCourseEnrol={handleCourseEnrol}
        submitted={submitted}
        setSubmitted={setSubmitted}
        item={item}
        setItem={setItem}              
        routeName="Home"        
        className="white-skin"  />
        

      {/*
      <Topbar />
     */ }

    {/*
    <NavBarMultiPage />
    */ }



      <div className="flexible-content white-skin">

          <main style={{paddingTop:'40px'}}>
           {
              messageName && messageType &&
              <Message
                messageName={messageName}
                messageType={messageType}
                removeMessage={removeMessage} />
            }
            <Routes 
              appProps={{ 
                      createMessage,
                      removeMessage,
                      handleCourseEnrol,
                      submitted,
                      setSubmitted,
                      item,
                      setItem 
                      }} />
          </main>
        </div>
        <ScrollUpBtn />
      
      </Page>
    </div>
    </React.Suspense>
  )
}

export default withRouter(App); 


