import React from 'react'
import { MDBContainer, MDBAlert } from 'mdbreact';

const Message = (props) =>{

	console.log(props)

	return (
    <MDBContainer className='mt-5'>
      <MDBAlert color={props.messageType} dismiss onClose={()=>{props.removeMessage()}}>
        <strong>{props.messageName}</strong>
      </MDBAlert>
    </MDBContainer>
  );

	// return(
			
	// 		<div className={`alert alert-${props.messageType} ml-6 mt-6 text-center`}>
	// 			<span 
	// 				className="glyphicon glyphicon-exclamation-sign"
	// 				aria-hidden='true'>
	// 			</span>
	// 			<span>&nbsp;{props.messageName}</span>
	// 			<button
	// 				className='close'
	// 				data-dismiss='alert'
	// 				onClick={()=>{props.removeMessage()}}
	// 				>&times;</button>
	// 		</div>
			
	// 	)
}

export default Message